/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { destr } from 'destr'
import { acceptHMRUpdate, defineStore, type StateTree } from 'pinia'
import { ref } from 'vue'
import { decrypt, encrypt } from '~/src/UserInterface/Shared/Storage/useEncryptStorage'

const settingsPreviousRouteStore = defineStore('settings-previous-route', () => {
  const previousRoute = ref<{ name: string, params: Record<string, string | string[]> } | undefined>(undefined)

  return {
    previousRoute,
  }
}, {
  persist: {
    serializer: {
      serialize: (data: StateTree) => encrypt(JSON.stringify(data)),
      deserialize: (data: string) => decrypt(destr<string>(data)),
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(settingsPreviousRouteStore, import.meta.hot))
}

export default settingsPreviousRouteStore
